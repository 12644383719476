import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29')
];

export const server_loads = [];

export const dictionary = {
		"/(app)": [4,[2]],
		"/(app)/create-group": [5,[2]],
		"/(app)/create-ride": [6,[2]],
		"/(app)/cyclists": [7,[2]],
		"/(app)/groups": [8,[2]],
		"/(app)/groups/[groupId]": [9,[2]],
		"/(app)/groups/[groupId]/edit": [11,[2]],
		"/(app)/groups/[groupId]/[slug]": [10,[2]],
		"/invalid-token": [27],
		"/(auth)/login": [24,[3]],
		"/(auth)/password-reset": [~25,[3]],
		"/(app)/privacy": [12,[2]],
		"/(app)/profile": [13,[2]],
		"/(app)/profile/[userId]": [14,[2]],
		"/(app)/profile/[userId]/[slug]": [15,[2]],
		"/(auth)/register": [26,[3]],
		"/(app)/rides": [16,[2]],
		"/(app)/rides/[rideId]": [17,[2]],
		"/(app)/rides/[rideId]/edit": [19,[2]],
		"/(app)/rides/[rideId]/[slug]": [18,[2]],
		"/(app)/search": [20,[2]],
		"/(app)/settings/[slug]": [21,[2]],
		"/(app)/terms": [22,[2]],
		"/todo": [28],
		"/upgrade": [29],
		"/(app)/whats-new": [23,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';